import { Controller } from '@hotwired/stimulus';

import deepmerge from 'deepmerge';
import { v4 as uuidv4 } from 'uuid';
import { ToastMessage } from '../src/toast_message';

export default class extends Controller {
  static targets = [
    'caseWizardNestedAttributesPrimaryKey',
    'caseWizardNestedAttributesUuid',
    'partyNameRelativeToOtherCaseBasicInfoParties',
    'reorderable'
  ];

  initialize() {
    this.autosaveResolve = () => { };
    this.autosaveData = {};
    this.cocoonNestedFieldsToRemoveUponSave = [];
  }

  connect() {
    const lockedForm = document.querySelector('form.locked');
    if (!lockedForm) {
      this.setUpAutosave();
    }
    this.setCaseWizardNestedAttributesUuidOnCocoonInsert();
    this.caseLockedCheck();
  }

  setUpAutosave() {
    this.element.addEventListener('ajax:success', (e) => {
      this.autosaveData =
        deepmerge(
          this.autosaveData,
          e.detail[0],
          {
            customMerge: (key) => {
              // For reordered records, overwrite arrays instead of concatenating them.
              if (key === 'reordered_records') {
                return (existingData, newData) => {
                  return {
                    ...existingData,
                    ...newData
                  };
                };
              }
            }
          }
        );

      if (this.autosaveData.modified_records_with_case_wizard_nested_attributes_uuid !== undefined) {
        this.autosaveData.modified_records_with_case_wizard_nested_attributes_uuid.forEach((record) => {
          const primaryKeyHiddenField =
            this.caseWizardNestedAttributesPrimaryKeyTargets.find((caseWizardNestedAttributesPrimaryKeyTarget) => {
              return caseWizardNestedAttributesPrimaryKeyTarget.dataset.caseWizardNestedAttributesUuid ===
                record.case_wizard_nested_attributes_uuid;
            });

          if (primaryKeyHiddenField === undefined) {
            // https://masteringjs.io/tutorials/fundamentals/foreach-continue
            return;
          }

          primaryKeyHiddenField.value = record.primary_key_val;

          const nestedFields = primaryKeyHiddenField.closest('.nested-fields');
          if (nestedFields !== null) {
            Array.from(nestedFields.getElementsByClassName('remove_fields')).forEach((removeFieldsButton) => {
              removeFieldsButton.classList.add('existing');
              removeFieldsButton.classList.remove('dynamic');
            });
          }

          const reorderable =
            this.reorderableTargets.find((el) => {
              return el.contains(primaryKeyHiddenField) &&
                el.dataset.reorderableDomId === record.old_dom_id;
            });
          if (reorderable !== undefined) {
            reorderable.dataset.reorderableDomId = record.dom_id;

            Array.from(reorderable.getElementsByClassName('reorder-button')).forEach((reorderButton) => {
              reorderButton.value = reorderButton.value.replace(/(.*#)([^#]*)/, '$1${record.primary_key_val}');
            });
          }
        });

        this.cocoonNestedFieldsToRemoveUponSave.forEach((cocoonNestedFields) => {
          cocoonNestedFields.remove();
          this.cocoonNestedFieldsToRemoveUponSave.splice(
            this.cocoonNestedFieldsToRemoveUponSave.indexOf(cocoonNestedFields),
            1
          );
        });
      }

      this.updatePartyNames();

      if (this.autosaveData.reordered_records !== undefined) {
        for (const [_scope_identifier, scope] of Object.entries(this.autosaveData.reordered_records)) {
          const orderSiblings =
            scope.order_siblings
              .map((orderSibling) => {
                return this.reorderableTargets.find(el => el.dataset.reorderableDomId === orderSibling.dom_id);
              })
              .filter(el => el !== undefined);

          for (let i = 0; i < orderSiblings.length; i++) {
            if (i < orderSiblings.length - 1) {
              orderSiblings[i].parentElement.insertBefore(orderSiblings[i], orderSiblings[orderSiblings.length - 1]);
            }

            Array.from(orderSiblings[i].getElementsByClassName('reorder-button'))
              .find(el => el.dataset.direction === 'up')
              .hidden = i <= 0;
            Array.from(orderSiblings[i].getElementsByClassName('reorder-button'))
              .find(el => el.dataset.direction === 'down')
              .hidden = i >= orderSiblings.length - 1;
          }
        }
      }

      this.autosaveResolve();
    });
    this.element.addEventListener('ajax:error', () => {
      (new ToastMessage('Failed to save changes', 'danger')).show();
      this.autosaveResolve();
    });

    this.addChangeAutosaveListeners();
    this.element.addEventListener('cocoon:after-insert', () => {
      this.addChangeAutosaveListeners();
      this.autosave();
    });
    this.element.addEventListener('cocoon:after-remove', (e) => {
      this.cocoonNestedFieldsToRemoveUponSave.push(e.detail);

      this.autosave();
    });
  }

  addChangeAutosaveListeners() {
    Array.from(this.element.elements).forEach((element) => {
      if (element.tagName === 'FIELDSET') {
        return;
      }

      if (element.closest('.select2') !== null) {
        return;
      }

      if (element.dataset.changeAutosaveListenerAdded !== 'true') {
        element.dataset.changeAutosaveListenerAdded = true;
        element.addEventListener('change', () => {
          this.autosave();
        });
      }
    });
  }

  // Submit the form using Rails UJS
  autosave(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    let autosaveResolve;
    const autosaving = new Promise((resolve) => { autosaveResolve = resolve; });
    navigator.locks.request(this.element, () => {
      this.autosaveResolve = autosaveResolve;

      const oldRemote = this.element.dataset.remote;
      this.element.dataset.remote = true;

      const submitButton = document.createElement('input');
      submitButton.type = 'submit';
      this.element.append(submitButton);
      submitButton.click();
      submitButton.remove();

      if (oldRemote === undefined) {
        delete this.element.dataset.remote;
      } else {
        this.element.dataset.remote = oldRemote;
      }

      return autosaving;
    });
  }

  updatePartyNames() {
    if (this.autosaveData.parties_that_saved_change_to_name_relative_to_other_case_basic_info_parties === undefined) {
      return;
    }

    this.autosaveData.parties_that_saved_change_to_name_relative_to_other_case_basic_info_parties.forEach(
      (
        {
          id: partyId,
          name_relative_to_other_case_basic_info_parties: nameRelativeToOtherCaseBasicInfoParties,
          name_relative_to_other_case_basic_info_parties_possessive: nameRelativeToOtherCaseBasicInfoPartiesPossessive
        }
      ) => {
        this.partyNameRelativeToOtherCaseBasicInfoPartiesTargets
          .filter(el => parseInt(el.dataset.partyId) === partyId)
          .forEach((partyNameRelativeToOtherCaseBasicInfoPartiesTarget) => {
            if (partyNameRelativeToOtherCaseBasicInfoPartiesTarget.dataset.possessive === 'true') {
              partyNameRelativeToOtherCaseBasicInfoPartiesTarget.textContent =
                nameRelativeToOtherCaseBasicInfoPartiesPossessive;
            } else {
              partyNameRelativeToOtherCaseBasicInfoPartiesTarget.textContent =
                nameRelativeToOtherCaseBasicInfoParties;
            }
          });
      }
    );
  }

  setCaseWizardNestedAttributesUuidOnCocoonInsert() {
    this.element.addEventListener('cocoon:after-insert', (e) => {
      const parsedDocument = (new DOMParser()).parseFromString(e.detail, 'text/html');
      const parsedCaseWizardNestedAttributesUuidFields =
        parsedDocument.querySelectorAll('[data-case-wizard-form-target="caseWizardNestedAttributesUuid"]');
      const caseWizardNestedAttributesUuidFieldIds =
        Array.from(parsedCaseWizardNestedAttributesUuidFields).map(el => el.id);

      const caseWizardNestedAttributesUuidTargetsToSet =
        this.caseWizardNestedAttributesUuidTargets.filter((caseWizardNestedAttributesUuidTarget) => {
          return caseWizardNestedAttributesUuidFieldIds.includes(caseWizardNestedAttributesUuidTarget.id) &&
            caseWizardNestedAttributesUuidTarget.value === '';
        });

      caseWizardNestedAttributesUuidTargetsToSet.forEach((caseWizardNestedAttributesUuidTarget) => {
        const caseWizardNestedAttributesFields =
          caseWizardNestedAttributesUuidTarget.closest('.case-wizard-nested-attributes-fields');
        const primaryKeyField =
          this.caseWizardNestedAttributesPrimaryKeyTargets.find((caseWizardNestedAttributesPrimaryKeyTarget) => {
            return caseWizardNestedAttributesPrimaryKeyTarget.closest('.case-wizard-nested-attributes-fields') ===
              caseWizardNestedAttributesFields;
          });

        const uuid = uuidv4();

        caseWizardNestedAttributesUuidTarget.value = uuid;
        primaryKeyField.dataset.caseWizardNestedAttributesUuid = uuid;
      });
    });
  }

  caseLockedCheck() {

    const lockedForm = document.querySelector('form.locked');

    if (lockedForm) {
      const inputs = lockedForm.querySelectorAll('input, textarea, select, button');
      inputs.forEach(input => {
        // buttons, select inputs, checkboxes
        if (input.type === 'checkbox' || input.type === 'radio' || input.tagName === 'SELECT' || input.tagName === 'BUTTON') {
          input.setAttribute('disabled', 'disabled');
        } else {
          input.setAttribute('readonly', 'readonly');
          input.setAttribute('disabled', 'disabled');
        }
      });

      // form-check-input
      const formCheckInputs = lockedForm.querySelectorAll('.form-check-input');
      formCheckInputs.forEach(input => {
        input.setAttribute('disabled', 'disabled');
      });

      // Disable links
      const links = lockedForm.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', function (event) {
          event.preventDefault();
          event.stopPropagation();
        });
        link.style.pointerEvents = 'none';
      });

      // select2
      const select2Elements = lockedForm.querySelectorAll('.select2-hidden-accessible');
      select2Elements.forEach(select2 => {
        $(select2).select2('disable');
      });

      lockedForm.addEventListener('submit', function (event) {
        event.preventDefault();
      });
    }
  }
}
