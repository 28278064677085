import { Controller } from '@hotwired/stimulus';
import { ToastMessage } from '../src/toast_message';

export default class extends Controller {
  static targets = [
    'caseBasicInfoAffidavitDate',
    'caseBasicInfoAffidavitBlank',
  ];

  connect() {
  }

  onDateChange(event) {
    var dateValue = this.getDateAndBlankCheckbox()[0];
    if (dateValue !== null && dateValue !== "") {
        document.getElementById("case_basic_info_affidavit_blank").checked = false;
    }
    this.submitForm();
  }

  onCheckboxChange(event) {
    var cbTrueFalse = this.getDateAndBlankCheckbox()[1];
    if (cbTrueFalse) {
        document.getElementById("case_basic_info_affidavit_date").value = "";
    }
    this.submitForm();
  }

  getDateAndBlankCheckbox() {
    var dateValue = document.getElementById("case_basic_info_affidavit_date").value;
    var isChecked = document.getElementById("case_basic_info_affidavit_blank").checked;
    return [dateValue, isChecked];
  }

  submitForm() {
    var form = document.getElementById("financial_affidavit_date_form");
    form.submit();

    form.addEventListener('ajax:success', () => {
        this.showSuccessToast();
    });
  }

  showSuccessToast() {
    (new ToastMessage('Financial Affidavit Date has been successfully updated.', 'danger')).show();
  }
}