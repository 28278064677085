import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'yearlySelfEmploymentIncomeInput',
    'selfEmploymentBusinessName'
  ];

  connect() {
    this.yearlySelfEmploymentIncomeInputTargets.forEach((yearlySelfEmploymentIncomeInput) => {
      this.toggleYearlySelfEmploymentAmountDetailsButton(undefined, yearlySelfEmploymentIncomeInput);
    });
  }

  toggleYearlySelfEmploymentAmountDetailsButton(e, yearlySelfEmploymentIncomeInput) {
    if (yearlySelfEmploymentIncomeInput === undefined) {
      yearlySelfEmploymentIncomeInput = e.currentTarget;
    }

    const yearlySelfEmploymentIncomeInputDetailsModalButton =
      yearlySelfEmploymentIncomeInput.closest('td')
        .querySelector('.income-yearly-self-employment-amount-details-modal-button');

    const selfEmploymentBusinessName =
      this.selfEmploymentBusinessNameTarget;

    if (yearlySelfEmploymentIncomeInput.value === '' || parseFloat(yearlySelfEmploymentIncomeInput.value) === 0) {
      yearlySelfEmploymentIncomeInputDetailsModalButton.hidden = true;
    } else {
      yearlySelfEmploymentIncomeInputDetailsModalButton.hidden = false;

      if (yearlySelfEmploymentIncomeInput.dataset.wasZero === 'true') {
        yearlySelfEmploymentIncomeInputDetailsModalButton.click();
      }
    }

    if (this.yearlySelfEmploymentIncomeInputTargets.some((target) => !!target.value)) {
      selfEmploymentBusinessName.closest('tr').hidden = false;
    } else {
      selfEmploymentBusinessName.closest('tr').hidden = true;
    }

    yearlySelfEmploymentIncomeInput.dataset.wasZero =
      yearlySelfEmploymentIncomeInput.value === '' || parseFloat(yearlySelfEmploymentIncomeInput.value) === 0;
  }
}
