import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'yearlyChildCareExpensesAmount',
    'numChildrenInChildCare',
    'yearlyThirdPartyChildCareReimbursementsAmount',
    'requestingChildCareVariance',
    'childCareVariancePaymentAmount'
  ];

  connect () {
    this.requestingChildCareVarianceTargets.forEach((requestingChildCareVariance) => {
      this.checkRequestingChildCareVariance(requestingChildCareVariance);
    });
  }


  checkRequestingChildCareVariance (eventOrRequestingChildCareVariance) {
    let requestingChildCareVariance;
    if (eventOrRequestingChildCareVariance instanceof Event) {
      requestingChildCareVariance = eventOrRequestingChildCareVariance.currentTarget;
    } else {
      requestingChildCareVariance = eventOrRequestingChildCareVariance;
    }

    const yearlyChildCareExpensesAmountField =
      this.yearlyChildCareExpensesAmountTargets.find(el => el.dataset.partyId === requestingChildCareVariance.dataset.partyId);

    const numChildrenInChildCareField =
      this.numChildrenInChildCareTargets.find(el => el.dataset.partyId === requestingChildCareVariance.dataset.partyId);

    const yearlyThirdPartyChildCareReimbursementsAmountField =
      this.yearlyThirdPartyChildCareReimbursementsAmountTargets.find(el => el.dataset.partyId === requestingChildCareVariance.dataset.partyId);

    const childCareVariancePaymentAmountField =
      this.childCareVariancePaymentAmountTargets.find(el => el.dataset.partyId === requestingChildCareVariance.dataset.partyId);

    if (requestingChildCareVariance.checked === true) {
      yearlyChildCareExpensesAmountField.value = '';
      numChildrenInChildCareField.value = '';
      yearlyThirdPartyChildCareReimbursementsAmountField.value = '';
      yearlyChildCareExpensesAmountField.disabled = true;
      numChildrenInChildCareField.disabled = true;
      yearlyThirdPartyChildCareReimbursementsAmountField.disabled = true;
      childCareVariancePaymentAmountField.disabled = false;
    } else {
      childCareVariancePaymentAmountField.value = '';
      yearlyChildCareExpensesAmountField.disabled = false;
      numChildrenInChildCareField.disabled = false;
      yearlyThirdPartyChildCareReimbursementsAmountField.disabled = false;
      childCareVariancePaymentAmountField.disabled = true;
    }
  }
}
