import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  initiateReorder(e) {
    e.preventDefault();

    this.element.classList.toggle("hidden")
    const parentDiv = this.element.closest('.template-form-order');
    const saveButton = parentDiv.querySelector('.save-order');
    if (saveButton) {
      saveButton.classList.remove('hidden');
    }

    const addButton = parentDiv.querySelector('.add-category-button');
    if (addButton) {
      addButton.classList.add("hidden");
    }

    const handles = parentDiv.querySelectorAll('.sort-handle');
    handles.forEach((handle) => {
      handle.classList.toggle("hidden");
    });

    var expenseElements = parentDiv.querySelectorAll('.expenses-container');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });

    expenseElements = parentDiv.querySelectorAll('.remove-expense-group-button');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });

    expenseElements = parentDiv.querySelectorAll('.add-expense-button');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });
  }

  updateOrder(e) {
    e.preventDefault();
    this.element.classList.toggle("hidden")
    const parentDiv = this.element.closest('.template-form-order');
    const sortButton = parentDiv.querySelector('.sort-expense-groups')
    if (sortButton) {
      sortButton.classList.remove("hidden")
    }

    const addButton = parentDiv.querySelector('.add-category-button');
    if (addButton) {
      addButton.classList.remove("hidden");
    }

    const handles = parentDiv.querySelectorAll('.sort-handle');
    handles.forEach((handle) => {
      handle.classList.toggle("hidden");
    });

    var expenseElements = parentDiv.querySelectorAll('.expenses-container');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });

    expenseElements = parentDiv.querySelectorAll('.remove-expense-group-button');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });

    expenseElements = parentDiv.querySelectorAll('.add-expense-button');
    expenseElements.forEach((expenseElement) => {
      expenseElement.classList.toggle("hidden");
    });
  }
}
