import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sign-up-form"
export default class extends Controller {
  static targets = [
    'standardSubscriptionBox',
    'proSubscriptionBox',
    'selectTextStandard',
    'selectedTextStandard',
    'selectTextPro',
    'selectedTextPro',
    'subscriptionTypeField',
    'standardSubscriptionUnselectedIcon',
    'standardSubscriptionSelectedIcon',
    'proSubscriptionUnselectedIcon',
    'proSubscriptionSelectedIcon',
    'standardPriceText',
    'proPriceTextAnnual',
    'proPriceTextMonthly',
    'paymentPeriodContainer',
    'paymentFormContainer',
    'paymentPeriodRadios'
  ];

  selectStandardSubscription () {
    const subscriptionTypeField = this.subscriptionTypeFieldTarget;
    const paymentPeriodContainer = this.paymentPeriodContainerTarget;
    const paymentForm = this.paymentFormContainerTarget;
    const paymentFormStandardText = this.standardPriceTextTarget;
    const paymentFormProAnnualText = this.proPriceTextAnnualTarget;
    const paymentFormProMonthlyText = this.proPriceTextMonthlyTarget;

    const standardSubscriptionTypeCard = this.standardSubscriptionBoxTarget;
    const standardSubscriptionSelectText = this.selectTextStandardTarget;
    const standardSubscriptionSelectedText = this.selectedTextStandardTarget;
    const standardUnselectedIcon = this.standardSubscriptionUnselectedIconTarget;
    const standardSelectedIcon = this.standardSubscriptionSelectedIconTarget;

    const proSubscriptionTypeCard = this.proSubscriptionBoxTarget;
    const proSubscriptionSelectText = this.selectTextProTarget;
    const proSubscriptionSelectedText = this.selectedTextProTarget;
    const proSubscriptionUnselectedIcon = this.proSubscriptionUnselectedIconTarget;
    const proSubscriptionSelectedIcon = this.proSubscriptionSelectedIconTarget;

    // Show appropriate elements in Standard Box
    standardSubscriptionTypeCard.getElementsByClassName('card-footer')[0].classList.add('selected');
    standardSubscriptionSelectText.classList.add('hidden');
    standardSubscriptionSelectedText.classList.remove('hidden');
    standardUnselectedIcon.classList.add('hidden');
    standardSelectedIcon.classList.remove('hidden');

    // Hide appropriate elements in Pro box
    proSubscriptionTypeCard.getElementsByClassName('card-footer')[0].classList.remove('selected');
    proSubscriptionSelectText.classList.remove('hidden');
    proSubscriptionSelectedText.classList.add('hidden');
    proSubscriptionUnselectedIcon.classList.remove('hidden');
    proSubscriptionSelectedIcon.classList.add('hidden');

    // Show appropriate elements on Payment Form
    paymentPeriodContainer.classList.add('hidden');
    paymentForm.classList.remove('hidden');
    paymentFormProAnnualText.classList.add('hidden');
    paymentFormProMonthlyText.classList.add('hidden');
    paymentFormStandardText.classList.remove('hidden');

    subscriptionTypeField.value = 'standard';
    document.getElementById('team_billing_frequency_annually').checked = true;
  }

  selectProSubscription () {
    const subscriptionTypeField = this.subscriptionTypeFieldTarget;
    const paymentPeriodContainer = this.paymentPeriodContainerTarget;
    const paymentForm = this.paymentFormContainerTarget;
    const paymentFormStandardText = this.standardPriceTextTarget;
    const paymentFormProAnnualText = this.proPriceTextAnnualTarget;
    const paymentFormProMonthlyText = this.proPriceTextMonthlyTarget;

    const standardSubscriptionTypeCard = this.standardSubscriptionBoxTarget;
    const standardSubscriptionSelectText = this.selectTextStandardTarget;
    const standardSubscriptionSelectedText = this.selectedTextStandardTarget;
    const standardUnselectedIcon = this.standardSubscriptionUnselectedIconTarget;
    const standardSelectedIcon = this.standardSubscriptionSelectedIconTarget;

    const proSubscriptionTypeCard = this.proSubscriptionBoxTarget;
    const proSubscriptionSelectText = this.selectTextProTarget;
    const proSubscriptionSelectedText = this.selectedTextProTarget;
    const proSubscriptionUnselectedIcon = this.proSubscriptionUnselectedIconTarget;
    const proSubscriptionSelectedIcon = this.proSubscriptionSelectedIconTarget;

    // Hide appropriate elements in Standard Box
    standardSubscriptionTypeCard.getElementsByClassName('card-footer')[0].classList.remove('selected');
    standardSubscriptionSelectText.classList.remove('hidden');
    standardSubscriptionSelectedText.classList.add('hidden');
    standardUnselectedIcon.classList.remove('hidden');
    standardSelectedIcon.classList.add('hidden');

    // Show appropriate elements in Pro box
    proSubscriptionTypeCard.getElementsByClassName('card-footer')[0].classList.add('selected');
    proSubscriptionSelectText.classList.add('hidden');
    proSubscriptionSelectedText.classList.remove('hidden');
    proSubscriptionUnselectedIcon.classList.add('hidden');
    proSubscriptionSelectedIcon.classList.remove('hidden');

    paymentPeriodContainer.classList.remove('hidden');

    const annualPaymentRadio = document.getElementById('team_billing_frequency_annually');
    const monthlyPaymentRadio = document.getElementById('team_billing_frequency_monthly');

    if (annualPaymentRadio.checked === true) {
      paymentFormProAnnualText.classList.remove('hidden');
      paymentFormProMonthlyText.classList.add('hidden');
      paymentForm.classList.remove('hidden');
    } else if (monthlyPaymentRadio.checked === true) {
      paymentFormProMonthlyText.classList.remove('hidden');
      paymentFormProAnnualText.classList.add('hidden');
      paymentForm.classList.remove('hidden');
    } else {
      paymentFormProAnnualText.classList.add('hidden');
      paymentFormProMonthlyText.classList.add('hidden');
      paymentForm.classList.remove('hidden');
    }

    paymentFormStandardText.classList.add('hidden');

    subscriptionTypeField.value = 'pro';
  }

  togglePaymentForm (e) {
    const radioValue = e.target.value;
    const paymentForm = this.paymentFormContainerTarget;
    const paymentFormStandardText = this.standardPriceTextTarget;
    const paymentFormProAnnualText = this.proPriceTextAnnualTarget;
    const paymentFormProMonthlyText = this.proPriceTextMonthlyTarget;

    if (radioValue === 'annually') {
      paymentFormProAnnualText.classList.remove('hidden');
      paymentFormProMonthlyText.classList.add('hidden');
    }

    if (radioValue === 'monthly') {
      paymentFormProAnnualText.classList.add('hidden');
      paymentFormProMonthlyText.classList.remove('hidden');
    }

    paymentFormStandardText.classList.add('hidden');
    paymentForm.classList.remove('hidden');
  }
}
