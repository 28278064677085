import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'typeRadio',
    'typeSpecificFields'
  ];

  connect() {
    this.toggleTypeSpecificFields();
  }

  toggleTypeSpecificFields() {
    const checkedTypeRadio = this.typeRadioTargets.find(radio => radio.checked);
    const type = checkedTypeRadio === undefined ? undefined : checkedTypeRadio.value;

    this.typeSpecificFieldsTargets.forEach((typeSpecificFields) => {
      const showTypeSpecificFields = typeSpecificFields.dataset.type === type;

      typeSpecificFields.hidden = !showTypeSpecificFields;
      Array.from(typeSpecificFields.querySelectorAll('input, textarea, select'))
        .filter((input) => {
          return input.closest('.select2') === null;
        })
        .forEach((input) => {
          input.disabled = !showTypeSpecificFields;
          input.required = showTypeSpecificFields;
        });
    });
  }
}
