import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'massEmailsTest',
  ];

  sendTestEmail () {
    this.massEmailsTestTarget.value = true;
  }
}
