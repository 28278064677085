import { Controller } from "@hotwired/stimulus"
import {ToastMessage} from "../src/toast_message";

// Connects to data-controller="split-custody-worksheet-selector"
export default class extends Controller {
  sendStitchedPdfRequest(e) {
    e.preventDefault;

    const worksheetNumber = e.target.dataset.worksheetNumber;
    const successMessageId = 'worksheet-' + worksheetNumber + '-request-success-message';
    const errorMessageId = 'worksheet-' + worksheetNumber + '-request-error-message';
    const numberChildrenTextId = 'number-of-children-text-' + worksheetNumber;

    document.getElementById(successMessageId).classList.add('hidden');
    document.getElementById(errorMessageId).classList.add('hidden');

    const requestUrl = e.target.dataset.requestUrl;

    fetch(requestUrl, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      method: 'PATCH',
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.message === 'success') {
          const numChildren = e.target.dataset.numberOfChildren;
          let messageText;

          if (numChildren === 1 || numChildren === '1') {
            messageText = '1 child';
          } else {
            messageText = numChildren + ' ' + 'children';
          }

          document.getElementById(numberChildrenTextId).innerText = messageText;
          document.getElementById(successMessageId).classList.remove('hidden');
        } else {
          document.getElementById(errorMessageId).classList.remove('hidden');
        }
      })
      .catch((r) => {
        document.getElementById(errorMessageId).classList.remove('hidden');
      });
  }
}
