import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit() {
    this.element.form.requestSubmit();

    if (this.element.classList.contains('case-wizard-notes')) {
      if (this.element.value === '' || this.element.value === null) {
        this.element.classList.remove('case-note');
      } else {
        this.element.classList.add('case-note');
      }
    }
  }
}
