import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('input', () => { this.muteLabelsIfEmpty(); });
    this.element.addEventListener('change', () => { this.muteLabelsIfEmpty(); });

    this.muteLabelsIfEmpty();
  }

  muteLabelsIfEmpty() {
    const isEmpty = this.element.value === '';

    Array.from(this.element.labels).filter(l => !l.classList.contains('no-mute')).forEach((label) => {
      label.classList.toggle('text-muted', isEmpty);
    });
  }
}
