import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'amountInput',
    'calculatedAmountDisplay'
  ];

  initializeAmount(e) {
    const currencyFormatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })

    document.querySelectorAll('.selectedIncomeAnnualIncomeField').forEach((target)=> {
      target.classList.remove('selectedIncomeAnnualIncomeField')
    })
    var annualIncomeField = e.target.closest('.nested-fields').querySelector('.annualIncomeField')
    annualIncomeField.classList.add('selectedIncomeAnnualIncomeField')

    var amount = annualIncomeField.value
    document.getElementById('modalAmountInput').value = amount
    document.querySelector('[data-income-calculator-target="calculatedAmountDisplay"]').innerHTML = currencyFormatter.format(amount)
  }

  calculateAnnualIncome(e) {
    var multiplyBy = e.target.attributes['data-multipled-by'].value
    const currencyFormatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })

    var calculatedAmount = multiplyBy * this.amountInputTarget.value
    var displayedAmount = currencyFormatter.format(calculatedAmount)
    this.calculatedAmountDisplayTarget.innerHTML = displayedAmount
  }

  updateOtherIncomeAnnualIncome(e) {
    document.querySelector('.selectedIncomeAnnualIncomeField').value =
      (parseInt(this.calculatedAmountDisplayTarget.innerHTML.replace(/\D/g, '')) / 100.00)

    document.querySelector('.selectedIncomeAnnualIncomeField').dispatchEvent(new Event('change'))
  }
}
