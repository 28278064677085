import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    sign: Number
  };

  initialize() {
    this.forceSignedValueEvents = ['input', 'change'];
    this.forceSignedValuedEventListener = this.forceSignedValue.bind(this);
  }

  connect() {
    this.forceSignedValueEvents.forEach((forceSignedValueEvent) => {
      this.element.addEventListener(
        forceSignedValueEvent,
        this.forceSignedValuedEventListener
      );
    });
    this.forceSignedValue();
  }

  disconnect() {
    this.forceSignedValueEvents.forEach((forceSignedValueEvent) => {
      this.element.removeEventListener(
        forceSignedValueEvent,
        this.forceSignedValuedEventListener
      );
    });
  }

  forceSignedValue(e) {
    const value = parseFloat(this.element.value);

    if (isNaN(value)) {
      return;
    }

    const signedValue = Math.abs(value) * this.signValue;

    if (signedValue === value) {
      return;
    }

    this.element.value = signedValue;
    if (e !== undefined) {
      this.element.dispatchEvent(new Event(e.type));
    }
  }
}
