import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import select2 from "select-woo"

// Connects to data-controller="select2-remote"
export default class extends Controller {
  static targets = [
    "selectElement",
    "dataElement"
  ]

  initialize() {
    this.select2OpenHandler = this.select2Open.bind(this)
    this.changeHandler = this.change.bind(this)

    select2()
  }

  connect() {
    const that = this
    const $select = this.$select

    let minInputLength = $select.data("minInputLength")
    if (!minInputLength) {
      minInputLength = 3
    }

    $select.select2({
      closeOnSelect: true,
      selectionCssClass: ':all:',
      theme: 'bootstrap-5',
      templateResult: this.formatSearchResult,
      templateSelection: this.formatSelection,
      allowClear: true,
      minimumInputLength: minInputLength,
      placeholder: '',
      ajax: {
        data: function (params) {
          var query = {
            search: params.term,
          }
          return query;
        }
      }
    })

    // https://github.com/select2/select2/issues/5993 - fixes search box not getting focus
    $select.on('select2:open', this.select2OpenHandler)
    $select.on('change', this.changeHandler)
  }

  disconnect() {
    const $select = this.$select

    $select.off('change', this.changeHandler)
    $select.off('select2:open', this.select2OpenHandler)
    $select.select2('destroy')
  }

  get $select() {
    return $(this.selectElementTarget)
  }

  select2Open() {
    const searchField = this.$select.data('select2').$dropdown.find('.select2-search__field')[0]

    if (searchField === undefined) { //this *should* mean it's a multi select
      window.setTimeout(function () {
        const searchField = document.querySelectorAll('.select2-results__option')[0]
        if (searchField) searchField.focus()
      }, 1000)

      return
    }

    searchField.focus()
  }

  // Select2 triggers the jQuery `change` event, not the DOM `change` event
  // this makes it trigger the DOM event too.
  change(e) {
    if (!e.isTrigger) {
      return
    }

    e.currentTarget.dispatchEvent(new Event('change'))
  }

  formatSearchResult(node) {
    let result = `<div>${node.name}</div>`
    return $(result)
  }

  formatSelection(node) {
    return node.name
  }
}
