import {Controller} from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';

export default class extends Controller {
  static targets = [
    'totalNetValueOutput',
    'netWorthResourceCategory',
    'netWorthResourceCategoryValueOutput',
    'netWorthResourceCategoryEncumbranceOutput',
    'netWorthResourceCategoryNetValueOutput',
    'netWorthResourceAttrNamesRow',
    'netWorthResourceGeneralRow',
    'netWorthResourceCategoryInput',
    'netWorthResourceSubcategoryInput',
    'netWorthResourceValueInput',
    'netWorthResourceEncumbranceInput',
    'netWorthResourceNetValueOutput',
    'termPension',
    'pensionText',
    'pensionTextEncumbrance',
    'saveButton',
    'exclusionAmount'
  ];

  connect() {
    let inputs = document.querySelectorAll('input');
    let closebtns = document.querySelectorAll('.modal');
    let value = document.querySelector('#net_worth_resource_value');
    let encumbrance = document.querySelector('#net_worth_resource_encumbrance');
    let description = document.querySelector('#net_worth_resource_description');
    let printedNote = document.querySelector('#net_worth_resource_printed_note');
    let form = document.querySelector('#case-net-worth-modal-form');
    this.calculate();
    if (form.classList.contains('value')) {
      setTimeout(() => {
        value.focus();
      }, 400);
    } else if (form.classList.contains('encumbrance')) {
      setTimeout(() => {
        encumbrance.focus();
      }, 400);
    } else if (form.classList.contains('description')) {
      setTimeout(() => {
        description.focus();
      }, 400);
    } else if (form.classList.contains('printed-note')) {
      setTimeout(() => {
        printedNote.focus();
      }, 400);
    }

    closebtns.forEach((closebtn) => {
      closebtn.addEventListener('hidden.bs.modal', () => {
        location.reload();
      });
    });

    inputs.forEach((input) => {
      input.addEventListener('input', () => {
        this.calculate();
      });
    });
    this.termPensionTargets.forEach((termPension) => {
      this.termPensionCheck(termPension);
    });
    this.pensionTextTargets.forEach((pensionText) => {
      this.termPensionCheck(pensionText);
    });
    this.pensionTextEncumbranceTargets.forEach((pensionTextEncumbrance) => {
      this.termPensionCheck(pensionTextEncumbrance);
    });
    this.element.addEventListener('ajax:success', () => {
      this.calculate();
    });
  }

  calculate() {

    let netWorthResourceValue =
      parseFloat(
        this.netWorthResourceValueInputTarget.value
      );
    if (isNaN(netWorthResourceValue)) {
      netWorthResourceValue = 0;
    }
    let netWorthResourceEncumbrance =
      parseFloat(
        this.netWorthResourceEncumbranceInputTarget.value
      );
    if (isNaN(netWorthResourceEncumbrance)) {
      netWorthResourceEncumbrance = 0;
    }
    if (Math.abs(this.netWorthResourceValueInputTarget.dataset.valueAfterExclusion) > netWorthResourceValue) {
      this.saveButtonTarget.disabled = true;
      this.exclusionAmountTarget.hidden = false;
    }
    else {
      this.exclusionAmountTarget.hidden = true;
      this.saveButtonTarget.disabled = false;
    }
    const netWorthResourceNetValue = netWorthResourceValue + netWorthResourceEncumbrance;

    this.netWorthResourceNetValueOutputTarget.textContent = formatCurrency(netWorthResourceNetValue);
  }

  termPensionCheck(eventOrTermPension) {
    let termPension;

    if (eventOrTermPension instanceof Event) {
      termPension = eventOrTermPension.currentTarget;
    } else {
      termPension = eventOrTermPension;
    }

    const subCategory = termPension.dataset.subCategory;
    const rowUnknown = this.termPensionTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const pensionTermRow = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTermRowUnknownNotVerified = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknownTermOrPension = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTextUnknownTermOrPensionNotVerified = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknown = this.pensionTextTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const pensionTextEncumbranceUnknownTermOrPension = this.pensionTextEncumbranceTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTextEncumbranceUnknownTermOrPensionNotVerified = this.pensionTextEncumbranceTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextEncumbranceUnknown = this.pensionTextEncumbranceTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const netWorthResourceValueTermOrPension =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceValue =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');
    const netWorthResourceEncumbranceTermOrPension =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceEncumbrance =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');

    for (let k = 0; k < pensionTermRow.length; k++) {
      if (pensionTermRow[k].id === termPension.id && termPension.checked) {
        pensionTermRowUnknownNotVerified[k].checked = false;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceValueTermOrPension[k].hidden = true;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].hidden = true;
        pensionTextUnknownTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[k].hidden = true;
        pensionTextEncumbranceUnknownTermOrPension[k].hidden = false;
        pensionTextEncumbranceUnknownTermOrPensionNotVerified[k].hidden = true;
      }

      if (pensionTermRow[k].checked === false && pensionTermRowUnknownNotVerified[k].checked === false) {
        pensionTermRow[k].disabled = false;
        pensionTermRowUnknownNotVerified[k].disabled = false;
        netWorthResourceEncumbranceTermOrPension[k].hidden = false;
        netWorthResourceValueTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPension[k].hidden = true;
        pensionTextEncumbranceUnknownTermOrPension[k].hidden = true;
      }

    }

    for (let j = 0; j < pensionTermRowUnknownNotVerified.length; j++) {
      if (pensionTermRowUnknownNotVerified[j].id === termPension.id && termPension.checked) {
        pensionTermRow[j].checked = false;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceValueTermOrPension[j].hidden = true;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].hidden = true;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = false;
        pensionTextUnknownTermOrPension[j].hidden = true;
        pensionTextEncumbranceUnknownTermOrPensionNotVerified[j].hidden = false;
        pensionTextEncumbranceUnknownTermOrPension[j].hidden = true;
      }

      if (pensionTermRow[j].checked === false && pensionTermRowUnknownNotVerified[j].checked === false) {
        pensionTermRow[j].disabled = false;
        pensionTermRowUnknownNotVerified[j].disabled = false;
        netWorthResourceEncumbranceTermOrPension[j].hidden = false;
        netWorthResourceValueTermOrPension[j].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = true;
        pensionTextEncumbranceUnknownTermOrPensionNotVerified[j].hidden = true;
      }

    }

    for (let j = 0; j < rowUnknown.length; j++) {
      if (rowUnknown[j].checked === true) {
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceValue[j].hidden = true;
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceEncumbrance[j].value = 0.00;
        netWorthResourceEncumbrance[j].hidden = true;
        pensionTextUnknown[j].hidden = false;
        pensionTextEncumbranceUnknown[j].hidden = false;
      }

      if (rowUnknown[j].checked === false) {
        netWorthResourceEncumbrance[j].hidden = false;
        netWorthResourceValue[j].hidden = false;
        pensionTextUnknown[j].hidden = true;
        pensionTextEncumbranceUnknown[j].hidden = true;
      }

    }
  }
}
