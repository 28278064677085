import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'alert',
    'showButton'
  ];
  static values = {
    id: String
  };

  initialize() {
    this.localStorageHiddenKey = `hideable-alert:hidden:${this.idValue}`;
  }

  connect() {
    this.toggle();
  }

  toggle(e) {
    if (e !== undefined) {
      this.hidden = e.params.hidden;
      return;
    }

    this.alertTarget.hidden = this.hidden;
    this.showButtonTarget.hidden = !this.hidden;
  }

  get hidden() {
    return localStorage.getItem(this.localStorageHiddenKey) !== null;
  }

  set hidden(hidden) {
    if (hidden) {
      localStorage.setItem(this.localStorageHiddenKey, true);
    } else {
      localStorage.clear(this.localStorageHiddenKey);
    }
    this.toggle();
  }
}
