import { Controller } from '@hotwired/stimulus';
import applySubsequentHighlighted from '../src/apply_subsequent_highlighted';

export default class extends Controller {
  initialize() {
    this.highlightToggleEvents = ['mouseover', 'mouseout', 'focus', 'blur'];
    this.highlightToggledEventListener = this.toggleHighlight.bind(this);
  }

  connect() {
    this.highlightToggleEvents.forEach((highlightToggleEvent) => {
      this.element.addEventListener(
        highlightToggleEvent,
        this.highlightToggledEventListener
      );
    });
    this.element.parentElement.addEventListener(
      'highlight-if-hover-self-or-subsequent:highlightToggled',
      this.highlightToggledEventListener
    );

    this.dispatch(
      'highlightToggled',
      {
        detail: {
          element: this.element
        }
      }
    );

    this.toggleHighlight();
  }

  disconnect() {
    this.highlightToggleEvents.forEach((highlightToggleEvent) => {
      this.element.removeEventListener(
        highlightToggleEvent,
        this.highlightToggledEventListener
      );
    });
    this.element.parentElement.removeEventListener(
      'highlight-if-hover-self-or-subsequent:highlightToggled',
      this.highlightToggledEventListener
    );
  }

  toggleHighlight(e) {
    if (
      e !== undefined &&
        e.type === 'highlight-if-hover-self-or-subsequent:highlightToggled'
    ) {
      applySubsequentHighlighted(this.element, e.detail.element);
    } else {
      this.element.classList.toggle(
        'highlighted',
        this.element.matches(':hover') || this.element.matches(':focus')
      );
      this.dispatch(
        'highlightToggled',
        {
          detail: {
            element: this.element
          }
        }
      );
    }
  }
}
