import { Controller } from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';

export default class extends Controller {
  static targets = [
    'grossAnnualIncomeInput',
    'ipersRadio',
    'ipersContributionIdSelect',
    'ratePercentageInput',
    'yearlyContributionAmountOutput'
  ];

  static roundTo2Decimals(number) {
    return Math.round(number * 100) / 100;
  }

  connect() {
    this.toggleIpersFields();
    this.updateYearlyContributionAmount();
  }

  toggleIpersFields() {
    this.ipersContributionIdSelectTarget.disabled = !this.ipers;
    this.ipersContributionIdSelectTarget.closest('tr').hidden = !this.ipers;

    this.ratePercentageInputTarget.disabled = this.ipers;
    this.ratePercentageInputTarget.closest('tr').hidden = this.ipers;
  }

  updateYearlyContributionAmount() {
    this.yearlyContributionAmountOutputTarget.textContent =
      formatCurrency(this.yearlyContributionAmount);
  }

  get grossAnnualIncome() {
    const grossAnnualIncome =
      this.constructor.roundTo2Decimals(parseFloat(this.grossAnnualIncomeInputTarget.value));

    if (isNaN(grossAnnualIncome)) {
      return null;
    }

    return grossAnnualIncome;
  }

  get ipers() {
    const checkedIpersRadio = this.ipersRadioTargets.find(r => r.checked);

    if (checkedIpersRadio === undefined) {
      return null;
    }

    return checkedIpersRadio.value === 'true';
  }

  get rate() {
    let rate;

    if (this.ipers) {
      rate = parseFloat(this.ipersContributionIdSelectTarget.selectedOptions[0].dataset.rate);
    } else {
      rate = parseFloat(this.ratePercentageInputTarget.value) / 100;
    }

    if (isNaN(rate)) {
      return null;
    }

    return rate;
  }

  // See `PensionContributionJob#yearly_contribution_amount`
  get yearlyContributionAmount() {
    if (this.grossAnnualIncome === null || this.rate === null) {
      return null;
    }

    return this.constructor.roundTo2Decimals(this.grossAnnualIncome * this.rate);
  }
}
