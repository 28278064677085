import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'qualifiedBusinessIncomeRadio',
    'qualifiedTradeRadio',
    'qualifiedTradeFields',
    'qualifiedTradeIncomeFields'
  ];
  static values = {
    incomeId: Number
  };

  connect() {
    this.changeQualifiedBusinessIncome();
    this.changeQualifiedTrade();
    this.toggleQualifiedTrade();
    this.toggleQualifiedTradeIncomeFields();
  }

  changeQualifiedBusinessIncome() {
    const qualifiedBusinessIncomeRadio =
      this.qualifiedBusinessIncomeRadioTargets.find(qbir => qbir.checked);

    const qualifiedBusinessIncomeValueSpan =
      Array.from(qualifiedBusinessIncomeRadio.form.getElementsByClassName('income-qualified-business-income-value'))
        .find((incomeQualifiedBusinessIncomeValue) => {
          return parseInt(incomeQualifiedBusinessIncomeValue.dataset.incomeId) === this.incomeIdValue;
        });

    switch (qualifiedBusinessIncomeRadio.value) {
    case 'true':
      qualifiedBusinessIncomeValueSpan.textContent = 'Yes';
      break;
    case 'false':
      qualifiedBusinessIncomeValueSpan.textContent = 'No';
    }
  }

  changeQualifiedTrade() {
    const qualifiedTradeRadio =
      this.qualifiedTradeRadioTargets.find(qbir => qbir.checked);

    if (qualifiedTradeRadio === undefined) {
      return;
    }

    const qualifiedTradeValueSpan =
      Array.from(qualifiedTradeRadio.form.getElementsByClassName('income-qualified-trade-value'))
        .find((incomeQualifiedTradeValue) => {
          return parseInt(incomeQualifiedTradeValue.dataset.incomeId) === this.incomeIdValue;
        });

    switch (qualifiedTradeRadio.value) {
    case 'true':
      qualifiedTradeValueSpan.textContent = 'Yes';
      break;
    case 'false':
      qualifiedTradeValueSpan.textContent = 'No';
    }
  }

  toggleQualifiedTrade() {
    const qualifiedBusinessIncomeRadio =
      this.qualifiedBusinessIncomeRadioTargets.find(qbir => qbir.checked);

    this.qualifiedTradeFieldsTarget.hidden =
      qualifiedBusinessIncomeRadio.value !== 'true';

    const qualifiedTradeValueContainer =
      Array.from(qualifiedBusinessIncomeRadio.form.getElementsByClassName('income-qualified-trade-value-container'))
        .find((incomeQualifiedTradeValue) => {
          return parseInt(incomeQualifiedTradeValue.dataset.incomeId) === this.incomeIdValue;
        });
    qualifiedTradeValueContainer.hidden =
      qualifiedBusinessIncomeRadio.value !== 'true';
  }

  toggleQualifiedTradeIncomeFields() {
    const qualifiedTradeRadio =
      this.qualifiedTradeRadioTargets.find(qbir => qbir.checked);

    if (qualifiedTradeRadio === undefined) {
      return;
    }

    switch (qualifiedTradeRadio.value) {
    case 'true':
      this.qualifiedTradeIncomeFieldsTarget.hidden = true;
      break;
    case 'false':
      this.qualifiedTradeIncomeFieldsTarget.hidden = false;
    }
  }
}
