import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nested"]
  connect() {
    this.storeInitialState();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  storeInitialState() {
    this.initialState = this.element.innerHTML;
  }

  handleClickOutside(event) {
    const newTemplate = document.getElementById('new-template-modal');

    if (event.target === newTemplate) {
      this.resetForm(newTemplate);
    }

    const editTemplate = document.getElementById('edit-template-modal');

    if (event.target === editTemplate) {
      this.resetForm(editTemplate);
    }
  }

  removeAll() {
    this.nestedTargets.forEach(element => {
      let destroyInput = element.querySelector("input[name*='_destroy']");
      if (destroyInput) {
        destroyInput.value = "1";
      }
      element.style.display = 'none';
    });
  }

  pullFromCurrentPage(event) {
    event.preventDefault();

    const url = this.element.dataset.url;
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const options = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json' // Ensure this is set if you are sending JSON
      },
      responseType: 'turbo-stream'
    };

    fetch(url, options).then(response => {
      response.text().then(html => {
        Turbo.renderStreamMessage(html);
      });
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  showConfirmation(e) {
    e.preventDefault();

    const template_name = document.getElementById('template_name').value;
    const alert = document.getElementById('name_alert');
    const categoryAlert = document.getElementById('category_alert');
    const expenseAlert = document.getElementById('expense_alert');

    const categoryInputs = this.element.querySelectorAll('.template-group-name');
    const allCategoryFilled = Array.from(categoryInputs).every(input => input.value.trim() !== '');

    const expenseInputs = this.element.querySelectorAll('.template-expense-name');
    const allExpenseFilled = Array.from(expenseInputs).every(input => input.value.trim() !== '');


    if (!template_name) {
      alert.classList.remove('hidden');
      return;
    } else {
      alert.classList.add('hidden');
    }

    if (!allCategoryFilled) {
      categoryAlert.classList.remove('hidden');
      return;
    } else {
      categoryAlert.classList.add('hidden');
    }

    if (!allExpenseFilled) {
      expenseAlert.classList.remove('hidden');
      return;
    } else {
      expenseAlert.classList.add('hidden');
    }

    const templateForm = document.getElementById('template-form');
    const templateConfirmation = document.getElementById('template-confirmation');
    const prompt = document.getElementById('prompt');

    templateForm.classList.toggle('hidden');
    templateConfirmation.classList.toggle('hidden');
    prompt.classList.toggle('hidden');
  }

  resetForm() {
    this.element.innerHTML = this.initialState;
  }
}
