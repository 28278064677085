import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import { FetchRequest } from '@rails/request.js';
import { metaContent } from '@rails/request.js/src/lib/utils';

if (metaContent('sentry-enabled') === 'true') {
  Sentry.init({
    dsn: 'https://FAKE@FAKE.ingest.sentry.io/1', // Will be set via tunnel
    environment: 'FAKE', // Will be set via tunnel
    release: 'FAKE', // Will be set via tunnel
    tunnel: '/sentry_tools/tunnel',
    debug: false,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          new RegExp(`^(${window.location.protocol}//([^/:]*\\.)?${window.location.host})?(/|$)`)
        ]
      }),
      new ExtraErrorDataIntegration(),
      new OfflineIntegration()
    ],
    tracesSampleRate: parseFloat(metaContent('sentry-traces-sample-rate')),
    transportOptions: {
      headers: {
        'X-CSRF-Token': FetchRequest.prototype.csrfToken
      }
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
    beforeSend(event) {
      if (event.exception && metaContent('sentry-last-event-id') === '') {
        const userFeedbackModalButton = document.getElementById('user-feedback-modal-button');

        if (userFeedbackModalButton !== null) {
          const turboFrame =
            document.querySelector(userFeedbackModalButton.dataset.bsTarget).querySelector('turbo-frame');

          const url = new URL(turboFrame.src, window.location);

          turboFrame.id = `user_feedback__${event.event_id}`;

          url.searchParams.set('user_feedback[sentry_event_id]', event.event_id);
          turboFrame.src = url;

          userFeedbackModalButton.click();
        }
      }

      return event;
    }
  });
  Sentry.setUser({
    id: 'FAKE', // Will be set via tunnel
    email: 'FAKE', // Will be set via tunnel
    ip_address: 'FAKE' // Will be set via tunnel
  });
}
