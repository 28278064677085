import { Controller } from "@hotwired/stimulus"
import {ToastMessage} from "../src/toast_message";

// Connects to data-controller="claim-case-form"
export default class extends Controller {
  static targets = [
    'neitherPartyRepresented',
    'neitherPartyRepresentedLabel',
    'partyRepresented',
    'teamUpgradeForm'
  ];

  connect() {}

  setNeitherPartyRepresented() {
    const neitherPartyRepresented = this.neitherPartyRepresentedTarget;
    const neitherPartyRepresentedLabel = this.neitherPartyRepresentedLabelTarget;


    if (neitherPartyRepresented.checked) {
      neitherPartyRepresented.value = true;
      neitherPartyRepresentedLabel.value = true;
      const representedParty =
        this.partyRepresentedTargets.find(pr => pr.checked);
      if (representedParty) {
        representedParty.checked = false;
      }
    }
  }

  unsetNeitherPartyRepresented() {
    const representedParty =
      this.partyRepresentedTargets.find(pr => pr.checked);
    const neitherPartyRepresented = this.neitherPartyRepresentedTarget;
    const neitherPartyRepresentedLabel = this.neitherPartyRepresentedLabelTarget;

    if (representedParty) {
      neitherPartyRepresented.checked = false;
      neitherPartyRepresented.value = false;
      neitherPartyRepresentedLabel.value = false;
    }
  }

  toggleModalContents(e) {
    // TODO: Modify this to work with Claim Case Form
    // e.preventDefault();
    // const submitUrl = e.target.action;
    //
    // fetch(submitUrl, {
    //   headers: {
    //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    //   },
    //   method: 'POST',
    //   body: new FormData(e.target)
    // })
    //   .then((r) => r.json())
    //   .then((r) => {
    //     if (r.error === undefined || r.error === null) {
    //       if (r.team_upgrade.billing_frequency === 'annually') {
    //         this.annualUpgradeTextTarget.classList.remove('hidden');
    //       } else if (r.team_upgrade.billing_frequency === 'monthly') {
    //         this.monthlyUpgradeTextTarget.classList.remove('hidden');
    //       }
    //
    //       this.teamUpgradeFormTarget.classList.add('hidden');
    //       this.submitButtonTarget.classList.add('hidden');
    //       this.closeWithoutUpgradingButtonTarget.classList.add('hidden');
    //     } else {
    //       new ToastMessage('Upgrade Failed - please try again later', 'danger').show();
    //     }
    //   })
    //   .catch((r) => {
    //     alert('Something went wrong. Please try again later.');
    //   });
  }
}
