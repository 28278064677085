import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'actionRadio',
    'textByActionElement'
  ];

  connect() {
    this.setTextByAction();
  }

  setTextByAction() {
    const checkedActionRadio =
      this.actionRadioTargets.find(actionRadioTarget => actionRadioTarget.checked);

    if (checkedActionRadio === undefined) {
      return;
    }

    this.textByActionElementTargets.forEach((textByActionElementTarget) => {
      textByActionElementTarget.textContent =
        JSON.parse(textByActionElementTarget.dataset.textByAction)[checkedActionRadio.value];
    });
  }
}
