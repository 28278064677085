export default function formatCurrency(amount, opts = {}) {
  amount = parseFloat(amount);

  if (isNaN(amount)) {
    return undefined;
  }

  let numberFormat =
    new Intl.NumberFormat(
      'en-US',
      {
        style: 'currency',
        currency: 'USD'
      }
    );
  if (Object.keys(opts).length > 0) {
    numberFormat =
      new Intl.NumberFormat(
        'en-US',
        {
          ...numberFormat.resolvedOptions(),
          ...opts
        }
      );
  }

  return numberFormat.format(amount).replace('-$', '$-');
}
