import {Controller} from '@hotwired/stimulus';

// Connects to data-controller="print-errors-tooltip"
export default class extends Controller {
  static targets = [
    'tooltip'
  ];

  connect() {
  }

  toggle() {
    if (this.tooltipTarget !== undefined) {
      this.tooltipTarget.classList.toggle('hidden');
    }

  }
}
