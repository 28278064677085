import { Controller } from '@hotwired/stimulus';

import $ from 'jquery';

export default class extends Controller {
  static targets = ["select"];

  connect() {
    const fileInput = document.getElementById("team_team_logo");

    if (fileInput) {
      fileInput.addEventListener("change", this.showPreview.bind(this));
    }
  }


  selectFile() {
    const fileInput = document.getElementById('team_team_logo');
    if (fileInput) {
      fileInput.click();
    }
  }

  showPreview(event) {
    const file = event.target.files[0]; // Get the first selected file
    if (file && file.type.match("image.*")) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Hide the placeholder and show the image preview
        const img = document.getElementById("team-logo-preview");
        const placeholder = document.getElementById("image-placeholder");

        if (placeholder) {
          placeholder.style.display = "none"; // Hide the placeholder
        }

        if (img) {
          img.src = e.target.result; // Set the image source to the uploaded file
          img.style.display = "block"; // Show the image preview
        }
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }
  }
}
