import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['frame'];

  updateFrame() {
    const currentTimestamp = new Date().getTime();
    const url = new URL(this.frameTarget.src);
    url.searchParams.set('t', currentTimestamp);
    this.frameTarget.src = url.toString();
  }
}
