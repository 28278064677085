import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="split-custody-form"
export default class extends Controller {
  changeCustodyArrangement(e) {
    const custodyArrangementSelect = e.currentTarget
    const currentSelectedText = custodyArrangementSelect.options[custodyArrangementSelect.selectedIndex].text
    const courtOrderedOvernightsSelect = custodyArrangementSelect.closest("td").nextElementSibling.querySelector(".court-ordered-overnight-range-container");

    if (currentSelectedText == "Shared Care") {
      courtOrderedOvernightsSelect.classList.add('hidden');
    } else {
      courtOrderedOvernightsSelect.classList.remove('hidden');
    }
  }
}
