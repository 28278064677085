import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  closeModal(event) {
    const modalId = event.currentTarget.dataset.modalId;

    const modal = document.getElementById(modalId);
    if (modal) {
      const modalCloseButton = document.createElement('button');
      modalCloseButton.dataset.bsDismiss = 'modal';
      modal.append(modalCloseButton);
      modalCloseButton.click();
      modalCloseButton.remove();

      modal.parentElement.insertBefore(modal.cloneNode(), modal);
      modal.remove();
    }
  }

  closeSendExpensesModal(event) {
    event.preventDefault();
    const modal = document.querySelector('.send-expenses-modal'); // Target your modal
    if (modal) {
      modal.classList.remove('show');
    }
    location.reload();
  }
}

