import { Controller } from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';

export default class extends Controller {
  static targets = [
    'expenseMonthlyAmountInput',
    'totalExpensesMonthlyAmountOutput'
  ];

  connect() {
    this.element.addEventListener('cocoon:after-insert', () => {
      this.calculate();
    });
    this.element.addEventListener('cocoon:after-remove', () => {
      this.calculate();
    });
    this.element.addEventListener('ajax:success', () => {
      this.calculate();
    });
    this.calculate();

    this.preserveIncomeSummaryCollapsedStateUponTurboReplace();
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.preserveIncomeSummaryCollapsedStateUponTurboReplaceEventListener
    );
  }

  calculate() {
    const totalExpensesMonthlyAmount =
      this.expenseMonthlyAmountInputTargets.reduce(
        (sum, expenseMonthlyAmountInput) => {
          const expenseMonthlyAmount = parseFloat(expenseMonthlyAmountInput.value);
          if (isNaN(expenseMonthlyAmount)) {
            return sum;
          }
          return sum + expenseMonthlyAmount;
        }
        ,
        0
      );
    this.totalExpensesMonthlyAmountOutputTarget.textContent =
      formatCurrency(totalExpensesMonthlyAmount) + '/mo';
  }

  preserveIncomeSummaryCollapsedStateUponTurboReplace() {
    this.preserveIncomeSummaryCollapsedStateUponTurboReplaceEventListener = {
      handleEvent:
        async (e) => {
          const targetId = e.target.target;
          const existingTarget = document.getElementById(targetId);
          const action = e.target.action;

          if (existingTarget.classList.contains('case-budget-income-summary') && action === 'replace') {
            const newTarget = e.target.querySelector('template').content.getElementById(targetId);
            if (newTarget && newTarget.querySelector('.case-budget-income-summary-table') !== undefined &&
              newTarget.querySelector('.case-budget-income-summary-table') !== null) {
              newTarget.querySelector('.case-budget-income-summary-table').classList.toggle(
                'collapsed',
                existingTarget !== null &&
                existingTarget !== undefined &&
                existingTarget.querySelector('.case-budget-income-summary-table').classList.contains('collapsed')
              );
            }
          }
        }
    };

    document.addEventListener(
      'turbo:before-stream-render',
      this.preserveIncomeSummaryCollapsedStateUponTurboReplaceEventListener
    );
  }
}
