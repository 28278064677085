import $ from "jquery";

$(document).ready(function() {
  $(document).on('cocoon:after-insert', function(e) {
    var insertedItem = $(e.target).children().last();
    var uniqueId = new Date().getTime() + Math.random().toString(36).substr(2, 9);

    var expensesContainer = $(insertedItem).find('.expenses-container');
    expensesContainer.attr('id', 'template_expense_group_' + uniqueId + '_expenses');

    var addExpenseLink = expensesContainer.closest('.template-expense-group').find('.add-expense-button');
    addExpenseLink.attr('data-association-insertion-node', '#template_expense_group_' + uniqueId + '_expenses');
  });
});
