import { Controller } from '@hotwired/stimulus';

import $ from 'jquery';
import select2 from "select-woo"

export default class extends Controller {
  initialize() {
    this.select2OpenHandler = this.select2Open.bind(this);
    this.changeHandler = this.change.bind(this);

    select2();
  }

  connect() {
    const $select = this.$select;

    const select2Opts = {
      closeOnSelect: !this.element.multiple,
      selectionCssClass: ':all:',
      theme: 'bootstrap-5'
    };

    const emptyOption = Array.from(this.element.options).find(o => o.value === '');
    if (emptyOption !== undefined) {
      select2Opts.placeholder = emptyOption.textContent;
      select2Opts.allowClear = true;
    }

    if (this.element.dataset.colorBox == "true") {
      select2Opts.templateResult = this.formatColorBox.bind(this), // Bind to the controller context
        select2Opts.templateSelection = this.formatColorBox.bind(this)
    }

    $select.select2(select2Opts);

    const label = document.createElement('label');
    label.htmlFor = this.element.id;
    label.style.display = $select.data('select2').$container.css('display');
    label.classList.add('select2-offscreen'); // Has no effect besides hiding from capybara-select-2 where necessary
    $select.data('select2').$container.wrap(label);

    // https://github.com/select2/select2/issues/5993
    $select.on('select2:open', this.select2OpenHandler);

    $select.on('change', this.changeHandler);
  }

  disconnect() {
    const $select = this.$select;

    $select.off('change', this.changeHandler);

    $select.off('select2:open', this.select2OpenHandler);

    $select.select2('destroy');
  }

  get $select() {
    return $(this.element);
  }

  select2Open() {
    const searchField =
      this.$select.data('select2').$dropdown.find('.select2-search__field')[0];

    if (searchField === undefined) { //this *should* mean it's a multi select
      window.setTimeout(function () {
        const searchField = document.querySelectorAll('.select2-results__option')[0]
        if (searchField) searchField.focus()
      }, 1000)

      return
    }

    searchField.focus();
  }

  // Select2 triggers the jQuery `change` event, not the DOM `change` event;
  // this makes it trigger the DOM event too.
  change(e) {
    if (!e.isTrigger) {
      return;
    }

    this.element.dispatchEvent(new Event('change'));
  }

  formatColorBox(option) {

    const colors = {
      charcoal: "#4A4A4A",
      deep_purple: "#4F3771",
      navy_blue: "#373B71",
      dark_teal: "#366C75",
      forest_green: "#35593B",
      dark_red: "#682533",
      deep_orange: "#9B5135",
      dark_pink: "#983F6F"
    };

    // If it's an unselectable element like an optgroup, just return its text
    if (!option.id) {
      return option.text;
    }

    // Create the color box
    const colorBox = document.createElement("span");
    colorBox.style.height = "20px";
    colorBox.style.width = "25px";
    colorBox.style.backgroundColor = colors[option.element.value]; // Use the value as color
    colorBox.style.display = "inline-block";
    colorBox.style.marginRight = "10px";
    colorBox.style.marginBottom = "-4px";

    // Create a wrapper to hold both the color box and the text
    const wrapper = document.createElement("span");
    wrapper.appendChild(colorBox);
    wrapper.appendChild(document.createTextNode(option.text));

    return wrapper;
  }
}
