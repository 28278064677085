import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="income-tax-rules-form"
export default class extends Controller {
  static targets = [
    'incomeTaxRuleGoverningBodyDropdown',
    'federalStandardDeductionContainer',
    'stateStandardDeductionSingleContainer',
    'stateStandardDeductionMFSContainer',
    'stateStandardDeductionHoHContainer'
  ];

  connect(e) {
    this.incomeTaxRuleGoverningBodyDropdownTarget.dispatchEvent(new Event('change'));
  }

  toggleFederalStateTaxFields(e) {
    const governingBodyDropdown = e.target;

    if (governingBodyDropdown.value === 'state') {
      this.federalStandardDeductionContainerTarget.style.display = 'none';
      this.stateStandardDeductionSingleContainerTarget.style.display = 'block';
      this.stateStandardDeductionMFSContainerTarget.style.display = 'block';
      this.stateStandardDeductionHoHContainerTarget.style.display = 'block';
    } else if (governingBodyDropdown.value === 'federal') {
      this.federalStandardDeductionContainerTarget.style.display = 'block';
      this.stateStandardDeductionSingleContainerTarget.style.display = 'none';
      this.stateStandardDeductionMFSContainerTarget.style.display = 'none';
      this.stateStandardDeductionHoHContainerTarget.style.display = 'none';
    } else {
      this.federalStandardDeductionContainerTarget.style.display = 'none';
      this.stateStandardDeductionSingleContainerTarget.style.display = 'none';
      this.stateStandardDeductionMFSContainerTarget.style.display = 'none';
      this.stateStandardDeductionHoHContainerTarget.style.display = 'none';
    }
  }
}
