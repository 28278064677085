import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'customerRoleSelect',
    'statusSelect',
    'attorneyAdditionalChargeMessage'
  ];

  connect() {
    this.toggleAttorneyAdditionalChargeMessage();
  }

  toggleAttorneyAdditionalChargeMessage() {
    if (!this.hasAttorneyAdditionalChargeMessageTarget) {
      return;
    }

    this.attorneyAdditionalChargeMessageTarget.hidden = !this.billable;
  }

  toggleMaxUsersMessage () {
    const newRecordValue = document.getElementById('user-new-record-value').value;

    let allowedToAddUserCheck;

    if (newRecordValue) {
      allowedToAddUserCheck =
        document.getElementById('billable-users-alert') !== undefined &&
        document.getElementById('billable-users-alert') !== null &&
        (this.billable);
    } else {
      allowedToAddUserCheck =
        document.getElementById('billable-users-alert') !== undefined &&
        document.getElementById('billable-users-alert') !== null &&
        document.getElementById('customer_user_status').value === 'active' &&
        (this.billable);
    }

    if (
      allowedToAddUserCheck
    ) {
      document.getElementById('billable-users-alert').classList.remove('hidden');
    } else if (
      document.getElementById('billable-users-alert') !== undefined &&
      document.getElementById('billable-users-alert') !== null &&
      !this.billable
    ) {
      document.getElementById('billable-users-alert').classList.add('hidden');
    }
  }

  // See `User#billable?`
  get billable() {
    return ['pending_acceptance_of_terms_and_conditions', 'active'].includes(this.statusSelectTarget.value) &&
      this.customerRoleSelectTarget.value === 'attorney';
  }
}
