import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'monthlyCostInput',
    'withinReasonableCashMedicalSupportAmountMessage',
    'aboveReasonableCashMedicalSupportAmountMessage'
  ];
  static values = {
    reasonableCashMedicalSupportAmount: Number
  };

  connect() {
    this.toggleReasonableCashMedicalSupportAmountMessages();
  }

  toggleReasonableCashMedicalSupportAmountMessages() {
    const monthlyCost =
      Math.floor(parseFloat(this.monthlyCostInputTarget.value) * 100) / 100;

    if (isNaN(this.reasonableCashMedicalSupportAmountValue) || isNaN(monthlyCost)) {
      this.withinReasonableCashMedicalSupportAmountMessageTarget.hidden = true;
      this.aboveReasonableCashMedicalSupportAmountMessageTarget.hidden = true;
    } else {
      this.withinReasonableCashMedicalSupportAmountMessageTarget.hidden =
        monthlyCost > this.reasonableCashMedicalSupportAmountValue;
      this.aboveReasonableCashMedicalSupportAmountMessageTarget.hidden =
        monthlyCost <= this.reasonableCashMedicalSupportAmountValue;
    }
  }
}
