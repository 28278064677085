import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      handle: ".sort-handle",
      animation: 150,
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    // Logic to update the hidden list_order fields
    let items = this.element.querySelectorAll('.sortable-item');
    items.forEach((item, index) => {
      item.querySelector('input[name*="list_order"]').value = index + 1;
    });

    let headerItems = this.element.querySelectorAll('.header-sortable-item');
    headerItems.forEach((headerItem, index) => {
      headerItem.querySelector('input[name*="list_order"]').value = index + 1;
    });
  }
}

