import { Controller } from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';

export default class extends Controller {
  static targets = [
    'totalNetValueOutput',
    'netWorthResourceCategory',
    'netWorthResourceCategoryValueOutput',
    'netWorthResourceCategoryEncumbranceOutput',
    'netWorthResourceCategoryNetValueOutput',
    'netWorthResourceAttrNamesRow',
    'netWorthResourceGeneralRow',
    'netWorthResourceCategoryInput',
    'netWorthResourceSubcategoryInput',
    'netWorthResourceValueInput',
    'netWorthResourceEncumbranceInput',
    'netWorthResourceNetValueOutput',
    'termPension',
    'pensionText'
  ];

  connect() {
    this.listenToRemoveNetWorthResourceNoteRows();

    this.element.addEventListener('cocoon:after-insert', () => {
      this.calculate();
    });
    this.element.addEventListener('cocoon:after-remove', () => {
      this.calculate();
    });
    this.element.addEventListener('ajax:success', () => {
      this.calculate();
    });
    this.calculate();

    this.element.addEventListener('cocoon:after-insert', () => {
      this.toggleNetWorthResourceAttrNamesRowsStates();
    });
    this.element.addEventListener('cocoon:after-remove', () => {
      this.toggleNetWorthResourceAttrNamesRowsStates();
    });
    this.toggleNetWorthResourceAttrNamesRowsStates();
    this.termPensionTargets.forEach((termPension) => {
      this.termPensionCheck(termPension);
    });
    this.pensionTextTargets.forEach((pensionText) => {
      this.termPensionCheck(pensionText);
    });
  }

  calculate() {
    let totalNetValue = 0;

    this.netWorthResourceCategoryTargets.forEach((netWorthResourceCategory) => {
      let categoryTotalValue = 0;
      let categoryTotalEncumbrance = 0;
      let categoryTotalNetValue = 0;

      this.netWorthResourceGeneralRowTargets
        .filter(el => netWorthResourceCategory.contains(el))
        .forEach((netWorthResourceGeneralRow) => {
          if (netWorthResourceGeneralRow.style.display === 'none') { // Row was removed by Cocoon
            return;
          }

          let netWorthResourceValue =
            parseFloat(
              this.netWorthResourceValueInputTargets
                .find(el => netWorthResourceGeneralRow.contains(el))
                .value
            );
          if (isNaN(netWorthResourceValue)) {
            netWorthResourceValue = 0;
          }
          let netWorthResourceEncumbrance =
            parseFloat(
              this.netWorthResourceEncumbranceInputTargets
                .find(el => netWorthResourceGeneralRow.contains(el))
                .value
            );
          if (isNaN(netWorthResourceEncumbrance)) {
            netWorthResourceEncumbrance = 0;
          }
          const netWorthResourceNetValue = netWorthResourceValue + netWorthResourceEncumbrance;

          this.netWorthResourceNetValueOutputTargets
            .find(el => netWorthResourceGeneralRow.contains(el))
            .textContent = formatCurrency(netWorthResourceNetValue);

          categoryTotalValue += netWorthResourceValue;
          categoryTotalEncumbrance += netWorthResourceEncumbrance;
          categoryTotalNetValue += netWorthResourceNetValue;
          totalNetValue += netWorthResourceNetValue;
        });

      this.netWorthResourceCategoryValueOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalValue);
      this.netWorthResourceCategoryEncumbranceOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalEncumbrance);
      this.netWorthResourceCategoryNetValueOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalNetValue);
    });

    this.totalNetValueOutputTarget.textContent =
      formatCurrency(totalNetValue);
  }

  listenToRemoveNetWorthResourceNoteRows() {
    this.element.addEventListener('cocoon:before-remove', (e) => {
      if (!e.detail.classList.contains('net-worth-resource-general-row')) {
        return;
      }

      let sibling = e.detail.nextElementSibling;
      while (sibling !== null) {
        if (sibling.classList.contains('net-worth-resource-note-row')) {
          sibling.parentNode.removeChild(sibling);
          break;
        }

        sibling = sibling.nextElementSibling;
      }
    });
  }

  toggleNetWorthResourceAttrNamesRowsStates() {
    this.netWorthResourceAttrNamesRowTargets.forEach((netWorthResourceAttrNamesRow) => {
      const hasAtLeastOneNetWorthResourceGeneralRows =
        this.netWorthResourceGeneralRowTargets.some((netWorthResourceGeneralRow) => {
          if (netWorthResourceGeneralRow.style.display === 'none') { // Row was removed by Cocoon
            return false;
          }

          const netWorthResourceCategoryInput =
            this.netWorthResourceCategoryInputTargets.find((netWorthResourceCategoryInput) => {
              return netWorthResourceGeneralRow.contains(netWorthResourceCategoryInput);
            });
          const netWorthResourceSubcategoryInput =
            this.netWorthResourceSubcategoryInputTargets.find((netWorthResourceSubcategoryInput) => {
              return netWorthResourceGeneralRow.contains(netWorthResourceSubcategoryInput);
            });

          let netWorthResourceCategory = netWorthResourceCategoryInput.value;
          if (netWorthResourceCategory === '') {
            netWorthResourceCategory = undefined;
          }
          let netWorthResourceSubcategory = netWorthResourceSubcategoryInput.value;
          if (netWorthResourceSubcategory === '') {
            netWorthResourceSubcategory = undefined;
          }

          return netWorthResourceCategory === netWorthResourceAttrNamesRow.dataset.netWorthResourceCategory &&
            netWorthResourceSubcategory === netWorthResourceAttrNamesRow.dataset.netWorthResourceSubcategory;
        });

      netWorthResourceAttrNamesRow.hidden = !hasAtLeastOneNetWorthResourceGeneralRows;
    });
  }
  termPensionCheck(eventOrTermPension) {
    let termPension;

    if (eventOrTermPension instanceof Event) {
      termPension = eventOrTermPension.currentTarget;
    } else {
      termPension = eventOrTermPension;
    }

    const subCategory = termPension.dataset.subCategory;
    const rowUnknown = this.termPensionTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const pensionTermRow = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTermRowUnknownNotVerified = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknownTermOrPension = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTextUnknownTermOrPensionNotVerified = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknown = this.pensionTextTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const netWorthResourceValueTermOrPension =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceValue =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');
    const netWorthResourceEncumbranceTermOrPension =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceEncumbrance =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');

    for (let k = 0; k < pensionTermRow.length; k++) {
      if (pensionTermRow[k].id === termPension.id && termPension.checked) {
        pensionTermRowUnknownNotVerified[k].checked = false;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceValueTermOrPension[k].hidden = true;
        netWorthResourceValueTermOrPension[k].parentNode.hidden = true;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].disabled = true;
        pensionTextUnknownTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[k].hidden = true;
      }

      if (pensionTermRow[k].checked === false && pensionTermRowUnknownNotVerified[k].checked === false) {
        pensionTermRow[k].disabled = false;
        pensionTermRowUnknownNotVerified[k].disabled = false;
        netWorthResourceValueTermOrPension[k].parentNode.hidden = false;
        netWorthResourceEncumbranceTermOrPension[k].disabled = false;
        netWorthResourceValueTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPension[k].hidden = true;
      }

    }

    for (let j = 0; j < pensionTermRowUnknownNotVerified.length; j++) {
      if (pensionTermRowUnknownNotVerified[j].id === termPension.id && termPension.checked) {
        pensionTermRow[j].checked = false;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceValueTermOrPension[j].hidden = true;
        netWorthResourceValueTermOrPension[j].parentNode.hidden = true;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].disabled = true;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = false;
        pensionTextUnknownTermOrPension[j].hidden = true;
      }

      if (pensionTermRow[j].checked === false && pensionTermRowUnknownNotVerified[j].checked === false) {
        pensionTermRow[j].disabled = false;
        pensionTermRowUnknownNotVerified[j].disabled = false;
        netWorthResourceValueTermOrPension[j].parentNode.hidden = false;
        netWorthResourceEncumbranceTermOrPension[j].disabled = false;
        netWorthResourceValueTermOrPension[j].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = true;
      }

    }

    for (let j = 0; j < rowUnknown.length; j++) {
      if (rowUnknown[j].checked === true) {
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceValue[j].hidden = true;
        netWorthResourceValue[j].parentNode.hidden = true;
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceEncumbrance[j].value = 0.00;
        netWorthResourceEncumbrance[j].disabled = true;
        pensionTextUnknown[j].hidden = false;
      }

      if (rowUnknown[j].checked === false) {
        netWorthResourceValue[j].parentNode.hidden = false;
        netWorthResourceEncumbrance[j].disabled = false;
        netWorthResourceValue[j].hidden = false;
        pensionTextUnknown[j].hidden = true;
      }

    }

  }
}
