export default function applySubsequentHighlighted(element, ...otherElements) {
  element.classList.remove('subsequent-highlighted');

  otherElements.forEach((otherElement) => {
    if (
      elementIsSiblingOfAndPrecedesOtherElement(element, otherElement) &&
      otherElement.classList.contains('highlighted')
    ) {
      element.classList.add('subsequent-highlighted');
    }
  });
}

function elementIsSiblingOfAndPrecedesOtherElement(element, otherElement) {
  let prevSibling = otherElement.previousElementSibling;
  while (prevSibling !== null) {
    if (prevSibling === element) {
      return true;
    }

    prevSibling = prevSibling.previousElementSibling;
  }

  return false;
}
