import { Controller } from "@hotwired/stimulus"
import {ToastMessage} from "../src/toast_message";

// Connects to data-controller="team-upgrade-form"
export default class extends Controller {
  static targets = [
    'submitButton',
    'downgradeAgreementCheckbox',
    'authorizedToUpgradeCheckbox',
    'monthlyUpgradeText',
    'annualUpgradeText',
    'teamUpgradeForm',
    'closeButton',
    'closeWithoutUpgradingButton'
  ];

  connect () {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const authorizedCheckbox = this.authorizedToUpgradeCheckboxTarget;
    const downgradeCheckbox = this.downgradeAgreementCheckboxTarget;
    const submitButton = this.submitButtonTarget;

    if (authorizedCheckbox.checked && downgradeCheckbox.checked) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  toggleModalContents(e) {
    e.preventDefault();
    const submitUrl = e.target.action;

    fetch(submitUrl, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      method: 'POST',
      body: new FormData(e.target)
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.error === undefined || r.error === null) {
          if (r.team_upgrade.billing_frequency === 'annually') {
            this.annualUpgradeTextTarget.classList.remove('hidden');
          } else if (r.team_upgrade.billing_frequency === 'monthly') {
            this.monthlyUpgradeTextTarget.classList.remove('hidden');
          }

          this.teamUpgradeFormTarget.classList.add('hidden');
          this.submitButtonTarget.classList.add('hidden');
          this.closeWithoutUpgradingButtonTarget.classList.add('hidden');
        } else {
          new ToastMessage('Upgrade Failed - please try again later', 'danger').show();
        }
      })
      .catch((r) => {
        alert('Something went wrong. Please try again later.');
      });
  }
}
