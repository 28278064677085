import { Controller } from '@hotwired/stimulus';

import Rails from '@rails/ujs';
import { loadStripe } from '@stripe/stripe-js/pure';

loadStripe.setLoadParameters({ advancedFraudSignals: false });

export default class extends Controller {
  static targets = [
    'stripeTokenIdentifierHiddenField',
    'cardElement',
    'cardErrors'
  ];
  static values = {
    publishableKey: String
  };

  initialize() {
    this.stripeTokenSet = false;
  }

  async connect() {
    this.stripe = await loadStripe(this.publishableKeyValue);
    this.setUpElements();
  }

  disconnect() {
    this.card.destroy();
  }

  setUpElements() {
    this.card = this.stripe.elements().create('card');
    this.card.mount(this.cardElementTarget);
    this.card.addEventListener('change', (e) => {
      if (e.error) {
        this.cardErrorsTarget.textContent = e.error.message;
      } else {
        this.cardErrorsTarget.textContent = '';
      }
    });
  }

  async createCardToken(e) {
    if (this.stripeTokenSet) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    Rails.disableElement(this.element);

    const stripeResult = await this.stripe.createToken(this.card);
    if (stripeResult.error === undefined) {
      this.stripeTokenIdentifierHiddenFieldTarget.value = stripeResult.token.id;

      this.stripeTokenSet = true;

      const submit = document.createElement('input');
      submit.type = 'submit';
      submit.hidden = true;
      this.element.append(submit);
      submit.click();
      submit.remove();
    } else {
      this.cardErrorsTarget.textContent = stripeResult.error.message;
      Rails.enableElement(this.element);
    }
  }
}
