import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'billingTypeRadio',
    'yearlySubscriptionCostInput',
    'overrideBillingContainer',
    'subscriptionTypeSelect',
    'billingFrequencySelect',
    'numberOfAttorneysContainer',
    'billingFrequencyContainer'
  ];

  connect() {
    this.toggleYearlySubscriptionCostApplicability();
  }

  toggleYearlySubscriptionCostApplicability() {
    const checkedBillingTypeRadio =
      this.billingTypeRadioTargets.find(radio => radio.checked);

    const yearlySubscriptionCostApplicable =
      checkedBillingTypeRadio !== undefined && checkedBillingTypeRadio.value === 'manual_subscription_cost';

    this.yearlySubscriptionCostInputTarget.required = yearlySubscriptionCostApplicable;
    this.yearlySubscriptionCostInputTarget.disabled = !yearlySubscriptionCostApplicable;

    if (yearlySubscriptionCostApplicable) {
      if (this.yearlySubscriptionCostInputTarget.dataset.oldVal !== undefined) {
        this.yearlySubscriptionCostInputTarget.value =
          this.yearlySubscriptionCostInputTarget.dataset.oldVal;
        delete this.yearlySubscriptionCostInputTarget.dataset.oldVal;
      }
    } else {
      if (this.yearlySubscriptionCostInputTarget.dataset.oldVal === undefined) {
        this.yearlySubscriptionCostInputTarget.dataset.oldVal =
          this.yearlySubscriptionCostInputTarget.value;
        this.yearlySubscriptionCostInputTarget.value = undefined;
      }
    }
  }

  toggleOverrideBilling () {
    const checkedBillingTypeRadio =
      this.billingTypeRadioTargets.find(radio => radio.checked);

    if (checkedBillingTypeRadio.value === 'normal' || checkedBillingTypeRadio.value === 'manual_subscription_cost') {
      this.overrideBillingContainerTarget.classList.remove('hidden')
    } else {
      this.overrideBillingContainerTarget.classList.add('hidden')
    }
  }

  toggleSubscriptionFrequency () {
    const subscriptionTypeSelect = this.subscriptionTypeSelectTarget;
    const billingFrequencyContainer = this.billingFrequencyContainerTarget;

    const teamBillingFree = document.getElementById('team_billing_type_free').checked;

    if (subscriptionTypeSelect.value === 'pro' && teamBillingFree) {
      billingFrequencyContainer.classList.add('hidden');
    } else if (subscriptionTypeSelect.value === 'pro' && !teamBillingFree) {
      billingFrequencyContainer.classList.remove('hidden');
    } else {
      billingFrequencyContainer.classList.add('hidden');
    }
  }

  toggleNumberOfAttorneysContainer () {
    const subscriptionTypeSelect = this.subscriptionTypeSelectTarget;
    const billingFrequencySelect = this.billingFrequencySelectTarget;
    const numberOfAttorneys = this.numberOfAttorneysContainerTarget;

    if (subscriptionTypeSelect.value === 'pro' && billingFrequencySelect.value === 'monthly') {
      numberOfAttorneys.classList.remove('hidden');
    } else {
      numberOfAttorneys.classList.add('hidden');
    }
  }
}
