import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'taxInfoScheduleRadio',
    'taxInfoScheduleTbody',
    'childSupportGuidelinesOff',
    'taxesTable'
  ];

  connect() {
    this.toggleTaxInfoScheduleTbodys();
  }

  toggleTaxInfoScheduleTbodys() {
    const checkedTaxInfoScheduleRadio =
      this.taxInfoScheduleRadioTargets.find(radio => radio.checked);

    if (checkedTaxInfoScheduleRadio && this.taxesTableTarget.classList.contains('collapse')) {
      this.taxesTableTarget.classList.toggle('collapse')
    }

    this.taxInfoScheduleTbodyTargets.forEach((taxInfoScheduleTbody) => {
      if (
        checkedTaxInfoScheduleRadio === undefined &&
        this.childSupportGuidelinesOffTarget !== undefined &&
        this.childSupportGuidelinesOffTarget.value
      ) {
        taxInfoScheduleTbody.hidden = taxInfoScheduleTbody.dataset.taxInfoSchedule !== 'fixed';
        return;
      }

      taxInfoScheduleTbody.hidden =
        checkedTaxInfoScheduleRadio === undefined ||
        taxInfoScheduleTbody.dataset.taxInfoSchedule !== checkedTaxInfoScheduleRadio.value;
    });
  }
}
