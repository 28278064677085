import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'caret'];

  connect() {
    const isCollapsed = localStorage.getItem('alert-collapsed');

    if (isCollapsed === 'true' && this.hasCaretTarget) {
      this.caretTarget.innerHTML = '<i class="fas fa-chevron-down"></i>';
      this.contentTarget.style.display = 'none';
      const collapsibleParent = this.element.closest('.collapsible');
      if (collapsibleParent) {
        collapsibleParent.classList.add('collapsed');
      }
    } else if (isCollapsed === 'false' && this.hasCaretTarget) {
      this.caretTarget.innerHTML = '<i class="fas fa-chevron-up"></i>';
      this.contentTarget.style.display = 'block';
      const collapsibleParent = this.element.closest('.collapsible');
      if (collapsibleParent) {
        collapsibleParent.classList.remove('collapsed');
      }
    }
    else {
      this.caretTarget.innerHTML = '<i class="fas fa-chevron-down"></i>';
    }
  }

  toggleState() {
    const collapsible = this.element.closest('.collapsible');
    collapsible.classList.toggle('collapsed');
    this.toggleCaret();

    const isCollapsed = collapsible.classList.contains('collapsed');
    localStorage.setItem('alert-collapsed', isCollapsed.toString());
  }

  toggleContent(event) {
    event.preventDefault();
    this.contentTarget.style.display =
      this.contentTarget.style.display === 'none' ? 'block' : 'none';

    this.toggleCaret();

    const isCollapsed = this.contentTarget.style.display === 'none';
    localStorage.setItem('alert-collapsed', isCollapsed.toString());
  }

  toggleCaret() {
    if (!this.hasCaretTarget) return;

    if (this.contentTarget.style.display === 'none') {
      this.caretTarget.innerHTML = '<i class="fas fa-chevron-down"></i>';
    } else {
      this.caretTarget.innerHTML = '<i class="fas fa-chevron-up"></i>';
    }
  }
}
