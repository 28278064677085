// Entry point for the build script in your package.json
import '@oddcamp/cocoon-vanilla-js';
import '@rails/actiontext';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import 'bootstrap';
import LocalTime from 'local-time';
import 'trix';

import './src';

// https://github.com/hotwired/turbo-rails/issues/79#issuecomment-761608052
import './controllers';
import '@hotwired/turbo-rails';

Rails.start();
LocalTime.start();
ActiveStorage.start();
